var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info" }, [
      _c("div", { staticClass: "container mb-5" }, [
        _c("h3", [_vm._v("個人情報の取り扱いについて")]),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "title" }, [_vm._v("事業者の氏名または名称")]),
        _c("div", [_vm._v("株式会社ロビンソン")]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "title" }, [_vm._v("個人情報保護管理者")]),
        _c("div", [
          _vm._v(
            "株式会社ロビンソン 管理部 中石 寿美礼（連絡先は、下記〔個人情報苦情及び相談窓口〕）"
          )
        ]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "title" }, [_vm._v("個人情報の利用目的")]),
        _c("div", [
          _vm._v(
            "個人情報の利用目的は下記の通りです。利用目的を超えて利用することはありません。"
          )
        ]),
        _c("div", [_vm._v("・ご意見、ご要望、お問い合わせに対する適切な回答")]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "title" }, [
          _vm._v("個人情報の第三者提供について")
        ]),
        _c("div", [
          _vm._v(
            "本人の同意がある場合または法令に基づく場合を除き、取得した個人情報を第三者に提供することはありません。"
          )
        ]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "title" }, [
          _vm._v("個人情報の取扱いの委託について")
        ]),
        _c("div", [
          _vm._v(
            "取得した個人情報の取扱いの全部または、一部を委託する場合があります。委託先については、個人情報取扱いに関する契約を締結するなど、適切な管理を行います。"
          )
        ]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "title" }, [
          _vm._v("保有個人データの開示等の請求等について")
        ]),
        _c("div", [
          _vm._v(
            "当社では、保有個人データに関する利用目的の通知、開示、内容の訂正・追加または削除、利用の停止・消去および第三者への提供の停止の請求（「開示等の請求等」といいます。）を受け付けております。その手続きについては、個人情報苦情及びご相談窓口へご連絡下さい。ただし、法令等の規定により開示等できない場合がございます。あらかじめご了承ください。"
          )
        ]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "title" }, [
          _vm._v("個人情報を入力するにあたっての注意事項")
        ]),
        _c("div", [
          _vm._v(
            "お名前とメールアドレスは必須項目です。これらを入力いただけない場合、お問い合わせできません。"
          )
        ]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "title" }, [
          _vm._v("本人が容易に認識できない方法による個人情報の取得")
        ]),
        _c("div", [
          _vm._v("クッキー等により画面表示情報の保持を行っています。")
        ]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "title" }, [
          _vm._v("個人情報の安全管理措置について")
        ]),
        _c("div", [
          _vm._v(
            "取得した個人情報については、漏洩、減失またはき損の防止と是正、その他個人情報の安全管理のために必要かつ適切な措置を講じます。このサイトは、SSL（Secure Socket Layer）による暗号化措置を講じています。"
          )
        ]),
        _c("p"),
        _c("p", { staticClass: "my-4" }),
        _c("div", { staticClass: "title" }, [
          _vm._v("個人情報苦情及び相談窓口")
        ]),
        _c("div", [_vm._v("株式会社ロビンソン 管理部")]),
        _c("div", [_vm._v("〒154-0005")]),
        _c("div", [
          _vm._v("東京都世田谷区三宿１丁目１３番１号 東映三宿ビル４階")
        ]),
        _c("div", [_vm._v("メール：G_privacy-protection@robinsons.co.jp")]),
        _c("p")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }